import React, {useEffect, useState} from "react";
import _ from "lodash";
import {TailSpin} from "react-loader-spinner";
import Combobox from "react-widgets/Combobox";
import "react-widgets/styles.css";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LoadingOverlay from 'react-loading-overlay-ts';
import Table from 'rc-table';
import SummarizeIcon from '@mui/icons-material/Summarize';

import {createTransaction, initializeOriginationContext, getOrderHistory, getOrderInfo,
  getDeveloperToken, getLoanData, getTransactionInfo} from "../service/encompassService";

import "../dimsum.css";
import "../Credit.css";
import "rc-table/assets/index.css";
import {useNavigate} from "react-router-dom";
import {downloadReport} from "../service/staircaseService";
import {create_health_metric, create_health_metric_with_job} from "../service/healthService";
let verificationType = "default"


function VerificationStartPage({ data }) {
  const navigate = useNavigate();
  const { host } = data;

  const [encompassOriginResult, setEncompassOriginResult] = useState([]);
  const [originationContext, setOriginationContext] = useState([]);
  const [transactionObject, setTransactionObject] = useState([]);
  const [loanInfo, setLoanInfo] = useState([]);
  const [developerToken, setDeveloperToken] = useState([]);

  const [loading, setLoading] = useState(false);
  const [overlayActive, setOverlayActive] = useState(true);
  const [softRefresh, setSoftRefresh] = useState(false);
  const [overlayMessage, setOverlayMessage] = useState("Loading borrower info...");
  const [error, setError] = useState(null);
  const [loanNumber, setLoanNumber] = useState("");
  const [loanName, setLoanName] = useState("");
  const [requestedBy, setRequestedBy] = useState("");
  const [defaultBorrower, setDefaultBorrower] = useState("");
  const [selectedBorrower, setSelectedBorrower] = useState("");
  const [borrowers, setBorrowers] = useState([]);
  const [borrowerPairsObjects, setBorrowerPairsObjects] = useState([]);

  const [requestType, setRequestType] = useState("New Request");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [reportOn, setReportOn] = useState("Individual");
  const [reportOnValues, setReportOnValues] = useState(["Individual"]);
  const [product, setProduct] = useState("Premier Credit Report");
  const [productValues, setProductValues] = useState(["Premier Credit Report", "SoftQual"]);
  const [experianChecked, setExperianChecked] = useState(true);
  const [equifaxChecked, setEquifaxChecked] = useState(true);
  const [transUnionChecked, setTransUnionChecked] = useState(true);


  const [borrowerLastName, setBorrowerLastName] = useState("");
  const [borrowerFirstName, setBorrowerFirstName] = useState("");
  const [borrowerSSN, setBorrowerSSN] = useState("");
  const [borrowerDOB, setBorrowerDOB] = useState("");
  const [borrowerStreetAddress, setBorrowerStreetAddress] = useState("");
  const [borrowerCity, setBorrowerCity] = useState("");
  const [borrowerState, setBorrowerState] = useState("");
  const [borrowerZip, setBorrowerZip] = useState("");

  const [coborrowerVisible, setCoborrowerVisible] = useState(false);
  const [coborrowerLastName, setCoborrowerLastName] = useState("");
  const [coborrowerFirstName, setCoborrowerFirstName] = useState("");
  const [coborrowerSSN, setCoborrowerSSN] = useState("");
  const [coborrowerDOB, setCoborrowerDOB] = useState("");
  const [coborrowerStreetAddress, setCoborrowerStreetAddress] = useState("");
  const [coborrowerCity, setCoborrowerCity] = useState("");
  const [coborrowerState, setCoborrowerState] = useState("");
  const [coborrowerZip, setCoborrowerZip] = useState("");
  const [orderHistory, setOrderHistory] = useState([]);
  const [rows, setRows] = useState([]);


  const [tabValue, setTabValue] = React.useState(0);


  function getValue(_obj, value){
    if(value in _obj)return _obj[value];
    else return "";
  }

  useEffect(() => {
    let domain = "", apiKey = "";
    const initVerification = async () => {
      setOverlayMessage("Loading borrower info...");
      setOverlayActive(true);
      console.log(window.sessionStorage)
      console.log(host)
      create_health_metric_with_job("STARTED", "Page loaded", window.location.host)
      let timeLimit = 20000;
      let failureValue = "Couldn't get data from Encompass"
      const timeoutPromise = new Promise((resolve, reject) => {
        let timeout = setTimeout(() => {
            resolve(failureValue);
        }, timeLimit);
    });
      let initResult;
      initResult = await Promise.race([initializeOriginationContext(host), timeoutPromise]);
      if(initResult === failureValue)
        throw failureValue;
      console.log(initResult)
      setEncompassOriginResult(initResult.encompassOriginResult);
      setOriginationContext(initResult.originationContext);
      setTransactionObject(initResult.transactionObject);
      create_health_metric_with_job("RUNNING", "Origination loaded finished", window.location.host)

      domain = _.get(
        initResult.encompassOriginResult.encompassOriginData,
        "response_payload.credentials.domain",
        ""
      );
      apiKey = _.get(
        initResult.encompassOriginResult.encompassOriginData,
        "response_payload.credentials.api_key",
        ""
      );
      let _developerToken
      try {
        create_health_metric_with_job("RUNNING", "Get developer token started", window.location.host)
        _developerToken = await getDeveloperToken(domain, apiKey)
        create_health_metric_with_job("RUNNING", "Get developer token finished", window.location.host)
        if (_developerToken === "ERROR") {
          throw "Couldn't get encompass credentials, please check if you entered correct credentials."
        }
      }
      catch(exc){
          throw "Couldn't get encompass credentials, please check if you entered correct credentials."
      }
      setDeveloperToken(_developerToken)
      setLoading(false);

      let entityId = initResult.encompassOriginResult.encompassOriginData.response_payload.entityRef.entityId;
      let entityIdParts = entityId.split(":");

      setLoanNumber(entityIdParts[entityIdParts.length - 1])
      let _loanNumber = entityIdParts[entityIdParts.length - 1]
      let _loan;
      try {
        create_health_metric_with_job("RUNNING", "Get loan data started", window.location.host)
        _loan = await getLoanData(_loanNumber, "_borrower1", _developerToken)
        create_health_metric_with_job("RUNNING", "Get loan data finished", window.location.host)
        if (_loan === "ERROR") {
          throw "Couldn't get loan data, please check if you entered correct credentials."
        }
      }
      catch(exc){
          throw "Couldn't get loan data, please check if you entered correct credentials."
      }

      let borrowerPairs = [];
      let _borrowerPairsObjects = [];
      let borrowerData = _loan["applications"]
      for(let i = 0; i < borrowerData.length; i++){
        let borrowerPairDetails = {};
        let borrower = borrowerData[i]["borrower"]
        let coborrower = borrowerData[i]["coborrower"]
        let borrowerFN, borrowerLN, coborrowerFN, coborrowerLN;
        borrowerFN = getValue(borrower, "firstName");
        borrowerLN = getValue(borrower, "lastName");
        coborrowerFN = getValue(coborrower, "firstName");
        coborrowerLN = getValue(coborrower, "lastName");
        let referenceNumber = getValue(borrowerData[i], "creditReportReferenceIdentifier");
        let pairKey = borrowerData[i]["id"];
        let pairName = borrowerFN + " " + borrowerLN;
        if(coborrowerFN !== "" && typeof coborrowerFN !== "undefined") pairName = pairName + " " + coborrowerFN + " " + coborrowerLN;
        let borrowerPairObject = {
          "name": pairName,
          "key": pairKey,
          "borrowerFirstName": borrowerFN,
          "borrowerLastName": borrowerLN,
          "borrowerDOB": borrower["birthDate"],
          "borrowerSSN": borrower["taxIdentificationIdentifier"],
          "coborrowerFirstName": coborrowerFN,
          "coborrowerLastName": coborrowerLN,
          "coborrowerDOB": coborrower["birthDate"],
          "coborrowerSSN": coborrower["taxIdentificationIdentifier"],
          "borrowerAddress":{},
          "coborrowerAddress":{},
          "referenceNumber": referenceNumber
        }
        if("residences" in borrower){
          borrowerPairObject["borrowerAddress"]["street"] = borrower["residences"][0]["addressStreetLine1"];
          borrowerPairObject["borrowerAddress"]["city"] = borrower["residences"][0]["addressCity"];
          borrowerPairObject["borrowerAddress"]["postalCode"] = borrower["residences"][0]["addressPostalCode"];
          borrowerPairObject["borrowerAddress"]["state"] = borrower["residences"][0]["addressState"];
          borrowerPairObject["borrowerAddress"]["country"] = borrower["residences"][0]["countryCode"];
        }
        if("residences" in coborrower){
          borrowerPairObject["coborrowerAddress"]["street"] = coborrower["residences"][0]["addressStreetLine1"];
          borrowerPairObject["coborrowerAddress"]["city"] = coborrower["residences"][0]["addressCity"];
          borrowerPairObject["coborrowerAddress"]["postalCode"] = coborrower["residences"][0]["addressPostalCode"];
          borrowerPairObject["coborrowerAddress"]["state"] = coborrower["residences"][0]["addressState"];
          borrowerPairObject["coborrowerAddress"]["country"] = coborrower["residences"][0]["countryCode"];
        }

        _borrowerPairsObjects.push(borrowerPairObject);
        const borrowerComboData = {
          "name": pairName,
          "key": borrowerData[i]["id"]
        };
        borrowerPairs.push(borrowerComboData)
      }
      // console.log(_borrowerPairsObjects)
      // console.log(borrowerPairs)
      setBorrowerPairsObjects(_borrowerPairsObjects);
      setBorrowers(borrowerPairs);
      setLoanInfo(_borrowerPairsObjects);
      setDefaultBorrower(borrowerPairs[0]);
      setLoanName(_loan["loanNumber"]);
      setRequestedBy(_loan["interviewersId"])

      create_health_metric_with_job("RUNNING", "All data loaded, page loaded successfully", window.location.host)
      await timeoutWait(500);
      await borrowerPairChanged(borrowerPairs[0], _borrowerPairsObjects)

      setOverlayActive(false)
    };
    initVerification().catch((error)=> {
      console.log(error)
      create_health_metric_with_job("FAILED", error.toString(), window.location.host)
      setOverlayActive(false)
      // alert("Couldn't load borrower info, please check if you entered correct credentials.")
      alert(error.toString())
      let p = closeInteraction(host)
    });
  }, []);

  const handleChange = (event, newValue, refresh = "") => {
    setTabValue(newValue);
    if(newValue == 1){
      let p = openNewPanel({"name": refresh})
    }
    if(newValue == 0){
      let p = borrowerPairChanged(selectedBorrower, null, 0)
    }
  };


  const columns = [
    { key: "date", dataIndex: "date", title: "ORDER DATE", editable: false, width: 400, align:"left" },
    { key: "refNumber", dataIndex: "refNumber", title: "REFERENCE NUMBER", editable: false, width: 400, align:"left"  },
    { key: "product", dataIndex: "product", title: "PRODUCT", editable: false, width: 400, align:"left"  },
    { key: "status", dataIndex: "status", title: "STATUS", editable: false, width: 400, align:"left", render(_status) {
        console.log("_status")
        console.log(_status)
        let status = _status["status"];
        console.log(status)
        let message = _status["message"]
        console.log(message)

        const handleMouseOver = (event) => {
          let elements = event.currentTarget.getElementsByClassName("status-error-message");
          if(elements.length === 1)
            elements[0].style.display = "block";
        };

        const handleMouseOut = (event) => {
          let elements = event.currentTarget.getElementsByClassName("status-error-message");
          if(elements.length === 1)
            elements[0].style.display = "none";
        };
        if(status === "COMPLETED")
          return <div className="status-wrapper"><div className="status-icon-completed" ></div><span className="status-name">{status}</span></div>
        else if(status === "FAILED")
          return <div className="status-wrapper status-wrapper-failed"
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                 >
                    <div className="status-icon-failed" ></div>
                    <span className="status-name-failed">{status}</span>
                    <div className="status-error-message">
                      <div className="status-error-message-text">{message}</div>
                    </div>
                </div>
        else
          return <div className="status-wrapper"><div className="status-icon" ></div><span className="status-name">{status}</span></div>
      }
    },
    { key: "documents", dataIndex: "documents", title: "DOCUMENTS", editable: false, width: 400, align:"left", render(id) {
          if(id === ""){
            return ""
          }
          return <button
                    className="em-ds-button white-button"
                    data-testid="button-wrapper" aria-disabled="false" aria-label="Download"
                    aria-pressed="false" tabIndex="0" type="button" onClick={() => viewFile(id)}> <SummarizeIcon style={{ color: '#006aa9' }} /></button>;
        }
    }
  ];

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  async function viewFile(id = "") {
    setOverlayActive(true);
    setOverlayMessage("Downloading report...");
    await downloadReport(id, domain, apiKey)
    setOverlayActive(false);
};

  async function openNewPanel(_selectedBorrower = {"name": ""}){
    let _orderHistory = [];
    let selectedBor = selectedBorrower;
    if(_selectedBorrower["name"] === "refresh"){
      setRows([])
    }
    else if(_selectedBorrower["name"] === "softRefresh"){
      setSoftRefresh(true);
    }
    else if(_selectedBorrower["name"] !== ""){
      selectedBor = _selectedBorrower
    }
    if(typeof orderHistory[selectedBor["key"]] === "undefined" || _selectedBorrower["name"] === "refresh" || _selectedBorrower["name"] === "softRefresh"){
      _orderHistory = orderHistory;
      if(_selectedBorrower["name"] !== "softRefresh") {
        setOverlayMessage("Loading order history...");
        setOverlayActive(true);
      }
      let applicationId = "";
      for(let b in borrowerPairsObjects){
        if(borrowerPairsObjects[b]["name"] === selectedBor["name"]){
          applicationId = borrowerPairsObjects[b]["key"];
        }
      }
      let oh = await getOrderHistory(loanNumber, domain, apiKey);
      _orderHistory[selectedBor["key"]] = oh;
      // console.log(_orderHistory)
      await setOrderHistory(_orderHistory);
    }
    else{
      _orderHistory = orderHistory;
    }
    let orderH = _orderHistory[selectedBorrower["key"]]["response_payload"]
    let autoRefresh = false;
    try {
      let _rows = [];
      let i = 0;
      for(let h in orderH) {
        if(i > 5)
          break;
        let order = orderH[h];
        let _row = {};
        if(order["serviceSetup"]["category"] !== "CREDIT")
          continue;

        // console.log("order")
        // console.log(order)
        if(!"transactionId" in order){
          continue;
        }
        let orderInfo = await getOrderInfo(order["transactionId"], domain, apiKey);
        // console.log("ORDER INFO")
        // console.log(orderInfo)

        _row["refNumber"] = "";
        _row["product"] = "";
        _row["documents"] = "";
        i++;
        if(orderInfo === "ERROR")
          continue;
        try {
          if ("response" in orderInfo["response_payload"] && (orderInfo["response_payload"]["response"]["status"] === "fulfilled" || orderInfo["response_payload"]["response"]["status"] === "completed")) {
            if (!("response" in orderInfo["response_payload"]) || !("referenceNumber" in orderInfo["response_payload"]["response"]) || (orderInfo["response_payload"]["response"]["referenceNumber"].indexOf("_") === -1)) {
              continue;
            } else {
              let encompassTransactionInfo = await getTransactionInfo(orderInfo["response_payload"]["response"]["referenceNumber"], domain, apiKey);
              // console.log("encompassTransactionInfo")
              // console.log(encompassTransactionInfo)
              if (encompassTransactionInfo === "ERROR") {
                continue;
              }
              if ("credit_request_datas" in encompassTransactionInfo) {
                if ("has_credit_request_type" in encompassTransactionInfo["credit_request_datas"][0]) {
                  _row["product"] = encompassTransactionInfo["credit_request_datas"][0]["has_credit_request_type"]["has_value"].toUpperCase();
                }
              }
              if ("credit_responses" in encompassTransactionInfo) {
                if ("has_credit_report_identifier" in encompassTransactionInfo["credit_responses"][0]) {
                  _row["refNumber"] = encompassTransactionInfo["credit_responses"][0]["has_credit_report_identifier"]["has_value"];
                }
                if ("with_credit_repository_included" in encompassTransactionInfo["credit_responses"][0]) {
                  let has_prior = 0;
                  if ("has_credit_repository_included_experian_indicator" in encompassTransactionInfo["credit_responses"][0]["with_credit_repository_included"][0]) {
                    if (encompassTransactionInfo["credit_responses"][0]["with_credit_repository_included"][0]["has_credit_repository_included_experian_indicator"]["has_value"] === true) {
                      if (has_prior === 0) {
                        _row["product"] += " [EX"
                      } else {
                        _row["product"] += ", EX"
                      }
                      has_prior++;
                    }
                  }
                  if ("has_credit_repository_included_equifax_indicator" in encompassTransactionInfo["credit_responses"][0]["with_credit_repository_included"][0]) {
                    if (encompassTransactionInfo["credit_responses"][0]["with_credit_repository_included"][0]["has_credit_repository_included_equifax_indicator"]["has_value"] === true) {
                      if (has_prior === 0) {
                        _row["product"] += " [EQ"
                      } else {
                        _row["product"] += ", EQ"
                      }
                      has_prior++;
                    }
                  }
                  if ("has_credit_repository_included_trans_union_indicator" in encompassTransactionInfo["credit_responses"][0]["with_credit_repository_included"][0]) {
                    if (encompassTransactionInfo["credit_responses"][0]["with_credit_repository_included"][0]["has_credit_repository_included_trans_union_indicator"]["has_value"] === true) {
                      if (has_prior === 0) {
                        _row["product"] += " [TU"
                      } else {
                        _row["product"] += ", TU"
                      }
                      has_prior++;
                    }
                  }
                  if (has_prior > 0) {
                    _row["product"] += "]"
                  }
                }
              }
            }
          }
          _row["date"] = order["created"];
          const date = new Date(_row["date"]);
          let day = ('0' + date.getDate()).slice(-2);
          let month = ('0' + (date.getMonth() + 1)).slice(-2);
          _row["date"] = month + "/" + day + "/" + date.getFullYear() + ", " + date.toLocaleTimeString();
          let status = "SUBMITTED";
          if ("response" in orderInfo["response_payload"] && "status" in orderInfo["response_payload"]["response"]) {
            status = orderInfo["response_payload"]["response"]["status"].toUpperCase();
          } else {
            status = order["status"].toUpperCase();
          }
          if (status === "FULFILLED")
            status = "COMPLETED";
          if (status === "PLACED") {
            status = "SUBMITTED";
            autoRefresh = true;
          }
          let message = "Credit verification failed";
          if (status === "FAILED") {
            if ("errors" in orderInfo["response_payload"]["response"]) {
              message = orderInfo["response_payload"]["response"]["errors"][0]["description"]
            }
          }
          _row["status"] = {
            "status": status,
            "message": message
          }
          if (status === "PROCESSING") {
            autoRefresh = true;
          }
          if ("documents" in order && status === "COMPLETED") {
            if (order["documents"].length > 0) {
              _row["documents"] = order["transactionId"];
            }
          }
          _rows.push(_row)
        }
        catch (e) {
          console.log("Parsing error")
        }
      }

      // console.log("_rows")
      // console.log(_rows)
      setRows(_rows)

      setOverlayActive(false);
      setSoftRefresh(false);
      if(autoRefresh){
        await timeoutWait(10000);
        openNewPanel({"name": "softRefresh"});
      }
    }
    catch (e) {
      console.log("NAPAK")
      console.log(e)
    }

  }

  async function borrowerPairChanged(value, _loanInfo = null, _tabValue = null){
    let lInfo = loanInfo;
    if(_loanInfo != null){
      lInfo = _loanInfo;
    }
    setSelectedBorrower(value);
    let real_loan_info = {};
    if(tabValue === 1 && _tabValue === null){
      await openNewPanel(value);
    }
    else {
      // console.log("LOAN INFO plu VALUE")
      // console.log(lInfo)
      // console.log(value)
      // console.log(loanNumber)
      for (let b in lInfo) {
        if (lInfo[b]["name"] === value["name"]) {
          real_loan_info = lInfo[b];
        }
      }
      if (real_loan_info === {}) {
        setOverlayMessage("Loading borrower info...");
        setOverlayActive(true)
        let applicationId = "";
        for (let b in borrowerPairsObjects) {
          if (borrowerPairsObjects[b]["name"] === value["name"]) {
            applicationId = borrowerPairsObjects[b]["key"];
          }
        }
      }
    }
    // console.log("borrowerPairsObjects")
    // console.log(lInfo)
    // console.log(real_loan_info)
    setBorrowerFirstName(real_loan_info["borrowerFirstName"]);
    setBorrowerLastName(real_loan_info["borrowerLastName"]);
    setBorrowerDOB(real_loan_info["borrowerDOB"]);
    setBorrowerSSN(real_loan_info["borrowerSSN"]);
    if("borrowerAddress" in real_loan_info) {
      setBorrowerStreetAddress(real_loan_info["borrowerAddress"]["street"]);
      setBorrowerCity(real_loan_info["borrowerAddress"]["city"]);
      setBorrowerState(real_loan_info["borrowerAddress"]["state"]);
      setBorrowerZip(real_loan_info["borrowerAddress"]["postalCode"]);
    }

    if(real_loan_info["coborrowerFirstName"] != ""){
      setCoborrowerFirstName(real_loan_info["coborrowerFirstName"]);
      setCoborrowerLastName(real_loan_info["coborrowerLastName"]);
      setCoborrowerDOB(real_loan_info["coborrowerDOB"]);
      setCoborrowerSSN(real_loan_info["coborrowerSSN"]);
      if("coborrowerAddress" in real_loan_info) {
        setCoborrowerStreetAddress(real_loan_info["coborrowerAddress"]["street"]);
        setCoborrowerCity(real_loan_info["coborrowerAddress"]["city"]);
        setCoborrowerState(real_loan_info["coborrowerAddress"]["state"]);
        setCoborrowerZip(real_loan_info["coborrowerAddress"]["postalCode"]);
      }
      setCoborrowerVisible(true)
      setReportOnValues(["Individual", "Joint"])
    }
    else{
      setCoborrowerVisible(false)
      setReportOnValues(["Individual", "Joint"])
    }
    if("referenceNumber" in real_loan_info && real_loan_info["referenceNumber"] !== ""){
      setRequestTypeFunction("Import Report/Reissue");
      setReferenceNumber(real_loan_info["referenceNumber"]);
    } else {
      setRequestTypeFunction("New Request");
      setReferenceNumber("");
    }
  }

  function setRequestTypeFunction(value){
    setRequestType(value);
    if(value == "New Request" || value == "New Request - Bypass Duplicate"){
      setProductValues(["Premier Credit Report", "SoftQual"])
      setProduct("Premier Credit Report")
      // setReferenceNumber("")
    }
    else{
      setProductValues([])
      setProduct("")
    }
  }

  function handleExperianChange(value){
    setExperianChecked(!experianChecked)
  }

  function handleEquifaxChange(value){
    setEquifaxChecked(!equifaxChecked)
  }

  function handleTransUnionChange(value){
    setTransUnionChecked(!transUnionChecked)
  }

  function borderColor(){
    if(!experianChecked && !equifaxChecked && !transUnionChecked)
      return {border: "1px solid rgb(198, 66, 82)", justifyContent: "space-evenly", paddingLeft: "10px", minHeight: "138px"}
    else
      return {border: "1px solid lightgrey", justifyContent: "space-evenly", paddingLeft: "10px", minHeight: "138px"}
  }

  function warningCreditBureau(){
    if(!experianChecked && !equifaxChecked && !transUnionChecked)
      return {visibility: "visible"}
    else
      return {visibility: "hidden"}
  }

  const verificationButtonClicked = async function (){
    // console.log("CREATE TRANSACTION")
    setOverlayMessage("Starting verification...");
    setOverlayActive(true)
    let options = {};
    if(requestType === "New Request")
      options["requestType"] = "NewOrder";
    else if(requestType === "New Request - Bypass Duplicate")
      options["requestType"] = "NewOrderBypass";
    else if(requestType === "Retrieve Existing Report") {
      options["requestType"] = "RetrieveExisting";
      options["creditReportIdentifier"] = referenceNumber;
    }  
    else if(requestType === "Import Report/Reissue") { 
      options["requestType"] = "Reissue";
      options["creditReportIdentifier"] = referenceNumber;
    } 
    else{
      options["requestType"] = requestType;
    }
    
    options["reportOn"] = reportOn;
    if(product === "SoftQual")options["SoftQual"]=true;
    else options["SoftQual"] = false;
    create_health_metric_with_job("RUNNING", "Start verification clicked", window.location.host)

    options["ApplicationID"] = selectedBorrower["key"];

    options["creditBureauExperian"] = experianChecked;
    options["creditBureauEquifax"] = equifaxChecked;
    options["creditBureauTransUnion"] = transUnionChecked;
    if(experianChecked === false && equifaxChecked === false && transUnionChecked === false){
      alert("Please select at least one credit bureau");
      setOverlayActive(false)
      return;
    }
    if(requestType !== "New Request" && requestType != "New Request - Bypass Duplicate" && referenceNumber.length < 3){
      alert("For reissue you need to enter reference number");
      setOverlayActive(false)
      return;
    }

    let requestObject = {
      request: {
        type: "Credit Verification",
        options: options,
      },
    };
    
    create_health_metric_with_job("RUNNING", "Before create transaction", window.location.host)
    await createTransaction(requestObject, transactionObject, encompassOriginResult);
    await timeoutWait(1000);

    create_health_metric_with_job("COMPLETED", "Create transaction success", window.location.host)
    let p = handleChange({}, 1, "refresh")
  }

  async function closeInteraction(host) {
    const transactionObject = await host.getObject("transaction");
    transactionObject.close();
  }


  let { encompassOriginData } = encompassOriginResult;

  const domain = _.get(
    encompassOriginData,
    "response_payload.credentials.domain",
    ""
  );
  const apiKey = _.get(
    encompassOriginData,
    "response_payload.credentials.api_key",
    ""
  );
  function redirect () {
    // console.log("BEFORE SENDING", verificationType)
    if (verificationType !== "default") {
      // history.push({
      //   pathname: "/result/single",
      //   state: {
      //     transactionRequests: [
      //       {
      //         transactionId: originationContext.transactionId,
      //       },
      //     ],
      //     domain,
      //     apiKey,
      //     verificationType,
      //   },
      // });
       navigate('/result/single');
    } else {
      setTimeout(redirect, 300); // try again in 300 milliseconds
    }
  }

  if (originationContext && originationContext.transactionId) {
    redirect();
  }

  function timeoutWait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

  function coborrowerAddres(){
    if(coborrowerVisible){
      return <div className="sc-qbn2kv-0 borrowerRow row3 col1_span5" direction="row" wrap="wrap" span="5" style={{alignContent: "center"}}>CoBorrower Current Address
                </div>;
    }
  }
  function coborrowerAddres1(){
    if(coborrowerVisible){
      return <div className="sc-qbn2kv-0 borrowerRow row3 col2_span4" direction="row" wrap="wrap" span="4">
                  <div
                      className="em-ds-form-item em-ds-form-item--disabled em-ds-form-item--default em-ds-form-item--variant-default "
                      id="coborrower_current_address_street">
                    <div className="em-ds-form-item-content  "><label
                        className="em-ds-label"></label>
                      <div className="em-ds-input__tooltip-ref  " aria-haspopup="true"
                           aria-expanded="false" style={{width: "100%"}}>
                        <div className="em-ds-input__tooltip-ref  " style={{width: "100%"}}>
                          <input
                              className="em-ds-input em-ds-input--clearable  input-disabled"
                              aria-label="Text Box" aria-required="false"
                              aria-multiline="false" aria-autocomplete="none"
                              data-testid="coborrower_current_address_street"
                              role="textbox" disabled maxLength="255" minLength="0"
                              name="" type="text" autoComplete="off" clearable="false"
                              value={coborrowerStreetAddress} /></div>
                      </div>
                    </div>
                  </div>
                </div>;
    }
  }

  function coborrowerAddres2(){
    if(coborrowerVisible){
      return <div className="sc-qbn2kv-0 borrowerRow row3 col3_span3" direction="row" wrap="wrap" span="3">
                  <div
                      className="em-ds-form-item em-ds-form-item--disabled em-ds-form-item--default em-ds-form-item--variant-default "
                      id="coborrower_current_address_city">
                    <div className="em-ds-form-item-content  "><label
                        className="em-ds-label"></label>
                      <div className="em-ds-input__tooltip-ref  " aria-haspopup="true"
                           aria-expanded="false" style={{width: "100%"}}>
                        <div className="em-ds-input__tooltip-ref  " style={{width: "100%"}}>
                          <input
                              className="em-ds-input em-ds-input--clearable  input-disabled"
                              aria-label="Text Box" aria-required="false"
                              aria-multiline="false" aria-autocomplete="none"
                              data-testid="coborrower_current_address_city" role="textbox"
                              disabled maxLength="255" minLength="0" name=""
                              type="text" autoComplete="off" clearable="false" value={coborrowerCity} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>;
    }
  }

  function coborrowerAddres3(){
    if(coborrowerVisible){
      return <div className="sc-qbn2kv-0 borrowerRow row3 col4_span3" direction="row" wrap="wrap" span="3">
                  <div
                      className="em-ds-form-item em-ds-form-item--disabled em-ds-form-item--default em-ds-form-item--variant-default "
                      id="coborrower_current_address_state">
                    <div className="em-ds-form-item-content  "><label
                        className="em-ds-label"></label>
                      <div className="em-ds-input__tooltip-ref  " aria-haspopup="true"
                           aria-expanded="false" style={{width: "100%"}}>
                        <div className="em-ds-input__tooltip-ref  " style={{width: "100%"}}>
                          <input
                              className="em-ds-input em-ds-input--clearable  input-disabled"
                              aria-label="Text Box" aria-required="false"
                              aria-multiline="false" aria-autocomplete="none"
                              data-testid="coborrower_current_address_state"
                              role="textbox" disabled maxLength="255" minLength="0"
                              name="" type="text" autoComplete="off" clearable="false"
                              value={coborrowerState} /></div>
                      </div>
                    </div>
                  </div>
                </div>;
    }
  }

  function coborrowerAddres4() {
    if (coborrowerVisible) {
      return <div className="sc-qbn2kv-0 borrowerRow row3 col5_span2" direction="row" wrap="wrap" span="2">
        <div
            className="em-ds-form-item em-ds-form-item--disabled em-ds-form-item--default em-ds-form-item--variant-default "
            id="coborrower_current_address_zip">
          <div className="em-ds-form-item-content  "><label
              className="em-ds-label"></label>
            <div className="em-ds-input__tooltip-ref  " aria-haspopup="true"
                 aria-expanded="false" style={{width: "100%"}}>
              <div className="em-ds-input__tooltip-ref  " style={{width: "100%"}}>
                <input
                    className="em-ds-input em-ds-input--clearable  input-disabled"
                    aria-label="Text Box" aria-required="false"
                    aria-multiline="false" aria-autocomplete="none"
                    data-testid="coborrower_current_address_zip" role="textbox"
                    disabled maxLength="255" minLength="0" name=""
                    type="text" autoComplete="off" clearable="false" value={coborrowerZip}/>
              </div>
            </div>
          </div>
        </div>
      </div>;
    }
  }

  function coborrowerInfo(){
    if(coborrowerVisible) {
      return <div className="sc-qbn2kv-0 borrowerRow row1 col1 coborrower_box" direction="row" wrap="wrap" span="1"
                  id="coBorrowerBioData" style={{gridTemplateColumns: "repeat(4, 1fr)", gap: "5px"}}>
        <div className="sc-qbn2kv-0 borrowerRow row1 col1" direction="row" wrap="wrap" span="20" width="" height=""
             style={{fontWeight: 600}}>CoBorrower
        </div>
        <div className="sc-qbn2kv-0 borrowerRow row2 col1" direction="row" wrap="wrap" span="6" width="" height="">
          <div
              className="em-ds-form-item em-ds-form-item--disabled em-ds-form-item--default em-ds-form-item--variant-default ">
            <div className="em-ds-form-item-content  "><label
                className="em-ds-label">Last Name</label>
              <div className="em-ds-input__tooltip-ref  " aria-haspopup="true"
                   aria-expanded="false" style={{width: "100%"}}>
                <div className="em-ds-input__tooltip-ref  "
                     style={{width: "100%"}}><input
                    className="em-ds-input em-ds-input--clearable em-ds-input--value  input-disabled"
                    aria-label="Text Box" aria-required="false"
                    aria-multiline="false" aria-autocomplete="none"
                    data-testid="coBorrower_Lastname" role="textbox" disabled
                    maxLength="255" minLength="0" name="" type="text"
                    autoComplete="off" clearable="false" value={coborrowerLastName}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sc-qbn2kv-0 borrowerRow row2 col2" direction="row" wrap="wrap" span="6" width="" height="">
          <div
              className="em-ds-form-item em-ds-form-item--disabled em-ds-form-item--default em-ds-form-item--variant-default ">
            <div className="em-ds-form-item-content  "><label
                className="em-ds-label">First Name</label>
              <div className="em-ds-input__tooltip-ref  " aria-haspopup="true"
                   aria-expanded="false" style={{width: "100%"}}>
                <div className="em-ds-input__tooltip-ref  "
                     style={{width: "100%"}}><input
                    className="em-ds-input em-ds-input--clearable em-ds-input--value  input-disabled"
                    aria-label="Text Box" aria-required="false"
                    aria-multiline="false" aria-autocomplete="none"
                    data-testid="coBorrower_Firstname" role="textbox"
                    disabled maxLength="255" minLength="0" name=""
                    type="text" autoComplete="off" clearable="false"
                    value={coborrowerFirstName}/></div>
              </div>
            </div>
          </div>
        </div>
        <div className="sc-qbn2kv-0 borrowerRow row2 col3" direction="row" wrap="wrap" span="4" width="" height="">
          <div
              className="em-ds-form-item em-ds-form-item--disabled em-ds-form-item--default em-ds-form-item--variant-default ">
            <div className="em-ds-form-item-content  "><label
                className="em-ds-label">SS#</label>
              <div className="em-ds-input__tooltip-ref  " aria-haspopup="true"
                   aria-expanded="false" style={{width: "100%"}}>
                <div className="em-ds-input__tooltip-ref  "
                     style={{width: "100%"}}><input
                    className="em-ds-input em-ds-input--clearable em-ds-input--value  input-disabled"
                    aria-label="Text Box" aria-required="false"
                    aria-multiline="false" aria-autocomplete="none"
                    data-testid="coBorrower_SSN" role="textbox" disabled
                    maxLength="255" minLength="0" name="" type="text"
                    autoComplete="off" clearable="false" value={coborrowerSSN}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sc-qbn2kv-0 borrowerRow row2 col4" direction="row" wrap="wrap" span="4" width="" height="">
          <div
              className="em-ds-form-item em-ds-form-item--disabled em-ds-form-item--default em-ds-form-item--variant-default ">
            <div className="em-ds-form-item-content  "><label
                className="em-ds-label">DOB</label>
              <div className="em-ds-input__tooltip-ref  " aria-haspopup="true"
                   aria-expanded="false" style={{width: "100%"}}>
                <div className="em-ds-input__tooltip-ref  "
                     style={{width: "100%"}}><input
                    className="em-ds-input em-ds-input--clearable em-ds-input--value  input-disabled"
                    aria-label="Text Box" aria-required="false"
                    aria-multiline="false" aria-autocomplete="none"
                    data-testid="coBorrower_Birthdate" role="textbox"
                    disabled maxLength="255" minLength="0" name=""
                    type="text" autoComplete="off" clearable="false"
                    value={coborrowerDOB}/></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  }

  function printProductCombobox(){
    if(requestType == "New Request" || requestType == "New Request - Bypass Duplicate") {
      return <Combobox
          defaultValue={product}
          value={product}
          data={productValues}
          id="product-combobox"
          onChange={value => setProduct(value)}
      />
    }
    else {
      return <Combobox
          defaultValue={product}
          value={product}
          data={productValues}
          id="product-combobox"
          onChange={value => setProduct(value)}
          disabled
      />
    }
  }

  function printDisabledReferenceNum(){
    if(requestType == "New Request" || requestType == "New Request - Bypass Duplicate") {
      return <div className="em-ds-input__tooltip-ref  "
           style={{width: "100%"}}>
        <input
          className="em-ds-input em-ds-input--clearable input-disabled"
          id = "refNumInput"
          aria-label="Text Box" aria-required="false"
          aria-multiline="false" aria-autocomplete="none"
          data-testid="referenceNo" role="textbox" maxLength="255"
          minLength="0" name="" type="text" autoComplete="off"
          clearable="false" value="" onChange={event => {setReferenceNumber(event.target.value);}} disabled/></div>
    }
    else{
      return <div className="em-ds-input__tooltip-ref  "
           style={{width: "100%"}}><input
          className="em-ds-input em-ds-input--clearable"
          id = "refNumInput"
          aria-label="Text Box" aria-required="false"
          aria-multiline="false" aria-autocomplete="none"
          data-testid="referenceNo" role="textbox" maxLength="255"
          minLength="0" name="" type="text" autoComplete="off"
          clearable="false" value={referenceNumber} onChange={event => {setReferenceNumber(event.target.value);}}/></div>
    }
  }


  return (
    <>
      {loading === true ? (
          <div style={{
            position: "absolute",
            left: "46%",
            top: "35%",
          }}>
            <TailSpin
              color="#00BFFF"
              height={120}
              width={120}
              id="loader"

            />
          </div>
      ) : (
          <div id="app-container">
              <LoadingOverlay
                active={overlayActive}
                spinner
                text={overlayMessage}
                className="loader-wrapper"
              >
            <div className="sc-njn2ro-0 eJKVlr">
              <div className="Toastify" id="tasks-toast"></div>
              <div className="sc-qbn2kv-0 jejIjy" cols="12" direction="row" wrap="wrap">
                <div className="sc-qbn2kv-0 ctPyOM" direction="row" wrap="wrap" span="12" width="" height="">
                  <div className="sc-1cgontk-1 kHNLAa em-container" id="app" role="button" tabIndex="0">
                    <div className="sc-qbn2kv-0 gBmNQV" cols="12" direction="row" wrap="wrap" id="OrderContainer">
                      <div className="sc-qbn2kv-0 ctPyOM" direction="row" wrap="wrap" span="12">
                        <div className="sc-qbn2kv-0 imdidV" cols="12" direction="row" wrap="wrap">
                          <div className="sc-qbn2kv-0 imLzLM" direction="row" wrap="wrap" span="6" width="" height=""
                               style={{gridColumn: "1 / span 10", gridTemplateColumns: "auto", columnGap: "8px", alignItems: "center", justifyContent: "flex-start"}}>
                            <p className="indexstyled__HeaderTitle-sc-12qw0oz-0 ffdUYw">Staircase</p></div>
                          <div className="sc-qbn2kv-0 eaFdIo" direction="row" wrap="wrap" span="3" width="" height=""
                               style={{gridColumn: "auto / span 2", justifyContent: "flex-end", alignItems: "center"}}>
                            {/*<button*/}
                            {/*    className="em-ds-button em-ds-button--text em-ds-button--m em-ds-button--variant-default  em-ds-button headerLink"*/}
                            {/*    data-testid="button-wrapper" aria-disabled="false" aria-label="Login Information"*/}
                            {/*    aria-pressed="false" tabIndex="0" type="button" style={{fontWeight: 500}}><span*/}
                            {/*    className="label-wrapper no-icon" data-testid="button-label" role="presentation"><span>Login Information</span></span>*/}
                            {/*</button>*/}
                          </div>
                          <div className="sc-qbn2kv-0 ctPyOM" direction="row" wrap="wrap" span="12" width="" height="">
                            <div className="em-ds-separator-wrapper em-ds-separator-wrapper--default em-ds-separator-wrapper--s em-ds-separator-wrapper--horizontal em-ds-separator-wrapper--initial" style={{marginTop: "0px"}}>
                              <div
                                  className="em-ds-separator em-ds-separator--default em-ds-separator--s em-ds-separator--horizontal em-ds-separator--category-level "></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sc-qbn2kv-0 ctPyOM" direction="row" wrap="wrap" span="12">
                        <div className="sc-qbn2kv-0 mainInfoRow" cols="12" direction="row" wrap="wrap"
                             style={{paddingRight: "20px"}}>
                          <div className="sc-qbn2kv-0 borrowerRow row1 col1 col1_span4" direction="row" wrap="wrap" span="4" width="" height="">
                            <div style={{maxWidth: "31vw"}}>
                              <div className="em-ds-form-item em-ds-form-item--default em-ds-form-item--variant-default "
                                   id="borrowerPair">
                                <div className="em-ds-form-item-content">
                                  <label className="em-ds-label">Borrowers <span className="em-ds-form-required-mark">●</span></label>

                                  <div className=" em-ds-combobox-container" data-testid="combobox" role="combobox">
                                    <div className=" css-2b097c-container">
                                      <span aria-live="polite" aria-atomic="false" aria-relevant="additions text" className="css-7pg0cj-a11yText"></span>
                                      <div>
                                        <Combobox
                                          defaultValue={defaultBorrower}
                                          value = {selectedBorrower}
                                          data={borrowers}
                                          textField="name"
                                          onChange={value => borrowerPairChanged(value)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sc-qbn2kv-0 borrowerRow row1 col2 col2_span2" direction="row" wrap="wrap" span="2" width="" height="">
                            <div
                                className="em-ds-form-item em-ds-form-item--readonly em-ds-form-item--default em-ds-form-item--variant-default "
                                id="requestedBy">
                              <div className="em-ds-form-item-content  "><label className="em-ds-label">Requested By</label>
                                <div className="em-ds-input__tooltip-ref" aria-haspopup="true" aria-expanded="false"
                                     style={{width: "100%"}}>
                                  <div className="em-ds-input__tooltip-ref" style={{width: "100%"}}><input
                                      className="em-ds-input em-ds-input--clearable em-ds-input--value"
                                      aria-label="Text Box" aria-required="false" aria-multiline="false"
                                      aria-autocomplete="none" data-testid="requestedBy" role="textbox" disabled={true}
                                      maxLength="255" minLength="0" name="" type="text" autoComplete="off" clearable="false"
                                      value={requestedBy} /></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sc-qbn2kv-0 borrowerRow row1 col3 col2_span2" direction="row" wrap="wrap" span="2" width="" height="">
                            <div
                                className="em-ds-form-item em-ds-form-item--readonly em-ds-form-item--default em-ds-form-item--variant-default "
                                id="loanNumber">
                              <div className="em-ds-form-item-content  "><label className="em-ds-label">Loan Number</label>
                                <div className="em-ds-input__tooltip-ref" aria-haspopup="true" aria-expanded="false"
                                     style={{width: "100%"}}>
                                  <div className="em-ds-input__tooltip-ref  " style={{width: "100%"}}><input
                                      className="em-ds-input em-ds-input--clearable em-ds-input--value  "
                                      aria-label="Text Box" aria-required="false" aria-multiline="false"
                                      aria-autocomplete="none" data-testid="loanNumber" role="textbox" disabled={true}
                                      maxLength="255" minLength="0" name="" type="text" autoComplete="off" clearable="false"
                                      value={loanName} /></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sc-qbn2kv-0 ctPyOM" direction="row" wrap="wrap" span="12" width="" height="" style={{display: "block"}}>
                        <div className="sc-1cgontk-0 ePqktn">
                          <div className="em-ds-tabs  " id="tab-container" aria-label="Tab list" role="tablist">
                            <div className="em-ds-tabs__tab-bar-container  ">
                              <div className="em-ds-tabs-tab-bar__list  " role="tablist" data-testid="tab-list"
                                   style={{width: "100%"}}>
                                <Box sx={{ width: '100%' }} className="creditBox">

                                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                                      <Tab label="Order Information" {...a11yProps(0)} />
                                      <Tab label="Order Status" {...a11yProps(1)} />
                                    </Tabs>
                                  </Box>
                                  <TabPanel value={tabValue} index={0} className="creditPanel">
                                    <div className="em-ds-tabs-panels  ">
                              <div style={{overflowX: "hidden"}}>
                                <div className="react-swipeable-view-container" style={{flexDirection: "row", transition: "all 0s ease 0s", direction: "ltr", display: "flex", willChange: "transform", transform: "translate(0%, 0px)"}}>
                                  <div aria-hidden="false" data-swipeable="true" style={{width: "100%", flexShrink: "0", overflow: "auto"}}>
                                    <div className="em-ds-tabs-panels__panel em-ds-tabs-panels__panel--default  "
                                         aria-labelledby="orderInformation-label" data-panel-id="orderInformation"
                                         role="tabpanel">
                                      <div className="sc-qbn2kv-0 jjxSOw" cols="12" direction="row" wrap="wrap">
                                        <div className="sc-qbn2kv-0 ctPyOM" direction="row" wrap="wrap" span="12" width=""
                                             height="">
                                          <div className="sc-qbn2kv-0 igcDpp" cols="12" direction="row" wrap="wrap">
                                            <div className="sc-qbn2kv-0 ctPyOM" direction="row" wrap="wrap" span="12"
                                                 width="" height="">
                                              <div className="sc-16dwl5f-3 ivUuCk">
                                                <div className="sc-qbn2kv-0 iJYhig" cols="12" direction="row" wrap="wrap"
                                                     style={{borderBottom: "1px solid rgb(169, 177, 190)", paddingBottom: "10px", marginBottom: "5px"}}>
                                                  <div className="sc-qbn2kv-0 rAGMc" direction="row" wrap="wrap" span="6"
                                                       width="" height=""
                                                       style={{gridTemplateColumns: "repeat(9, 1fr)", marginRight: "1px", paddingBottom: "10px", alignItems: "flex-start", borderRight: "1px solid rgb(169, 177, 190)"}}>
                                                    <div className="sc-qbn2kv-0 kYULFk" direction="row" wrap="wrap" span="4"
                                                         width="" height="">
                                                      <div style={{maxWidth: "20.7vw"}}>
                                                        <div
                                                            className="em-ds-form-item em-ds-form-item--default em-ds-form-item--variant-default "
                                                            id="requestType">
                                                          <div className="em-ds-form-item-content  "><label
                                                              className="em-ds-label">Request Type<span
                                                              className="em-ds-form-required-mark">●</span></label>
                                                            <div className=" em-ds-combobox-container" data-testid="combobox" role="combobox">
                                                              <Combobox
                                                                defaultValue={requestType}
                                                                data={["New Request", "Import Report/Reissue"]}
                                                                id="request-type-combobox"
                                                                onChange={value => setRequestTypeFunction(value)}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="sc-qbn2kv-0 kYULFk" direction="row" wrap="wrap" span="4"
                                                         width="" height="">
                                                      <div
                                                          className="em-ds-form-item em-ds-form-item--disabled em-ds-form-item--default em-ds-form-item--variant-default "
                                                          id="referenceNo">
                                                        <div className="em-ds-form-item-content  "><label
                                                            className="em-ds-label">Reference Number</label>
                                                          <div className="em-ds-input__tooltip-ref  " aria-haspopup="true"
                                                               aria-expanded="false" style={{width: "100%"}}>
                                                            {printDisabledReferenceNum()}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="sc-qbn2kv-0 kYULFk" direction="row" wrap="wrap" span="4"
                                                         width="" height="">
                                                      <div
                                                          className="em-ds-form-item em-ds-form-item--default em-ds-form-item--variant-default "
                                                          id="reportOn">
                                                        <div className="em-ds-form-item-content  "><label
                                                            className="em-ds-label">Report On<span
                                                            className="em-ds-form-required-mark">●</span></label>
                                                          <div className=" em-ds-combobox-container" data-testid="combobox" role="combobox">
                                                            <Combobox
                                                              defaultValue={reportOn}
                                                              data={reportOnValues}
                                                              id="report-on-combobox"
                                                              onChange={value => setReportOn(value)}
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="sc-qbn2kv-0 kYULFk" direction="row" wrap="wrap" span="4"
                                                         width="" height="">
                                                      <div
                                                          className="em-ds-form-item em-ds-form-item--default em-ds-form-item--variant-default "
                                                          id="ProductName">
                                                        <div className="em-ds-form-item-content  "><label
                                                            className="em-ds-label">Product<span
                                                            className="em-ds-form-required-mark">●</span></label>
                                                          <div className=" em-ds-combobox-container" data-testid="combobox" role="combobox">
                                                            {printProductCombobox()}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="sc-qbn2kv-0 bQYKjs" direction="row" wrap="wrap" span="2"
                                                       width="" height="">
                                                    <div className="sc-1xl8m6s-2 lojBjT">
                                                      <div className="sc-qbn2kv-0 cjDUoc" direction="row" wrap="wrap"
                                                           span="2" id="creditBureauContainer"
                                                           style={borderColor()}>
                                                        <div className="sc-qbn2kv-0 ctPyOM" direction="row" wrap="wrap"
                                                             span="12" width="" height=""><span
                                                            className="sc-1xl8m6s-0 gUnlas">Credit Bureaus</span></div>
                                                        <div className="sc-qbn2kv-0 bGePqk" direction="row" wrap="wrap"
                                                             span="12" width="" height="">
                                                          <div className="em-ds-checkbox-group em-ds-checkbox-group--vertical" id="selectedCreditBureau" role="group">
                                                            <div className="em-ds-form-element-checkbox em-ds-form-element-checkbox--default variant-default">
                                                              <label data-testid="ds-checkbox-label" className="em-ds-form-element-checkbox__label" htmlFor="checkExperian">
                                                                <input type="checkbox"
                                                                       id="checkExperian"
                                                                       checked={experianChecked}
                                                                       onChange={handleExperianChange}

                                                                /> <span className="em-ds-form-element-checkbox__label-text">Experian</span>
                                                              </label>
                                                            </div>
                                                            <div className="em-ds-form-element-checkbox em-ds-form-element-checkbox--default variant-default">
                                                              <label data-testid="ds-checkbox-label" className="em-ds-form-element-checkbox__label" htmlFor="checkEquifax">
                                                                <input type="checkbox"
                                                                       id="checkEquifax"
                                                                       checked={equifaxChecked}
                                                                       onChange={handleEquifaxChange}

                                                                /> <span className="em-ds-form-element-checkbox__label-text">Equifax</span>
                                                              </label>
                                                            </div>
                                                            <div className="em-ds-form-element-checkbox em-ds-form-element-checkbox--default variant-default">
                                                              <label data-testid="ds-checkbox-label" className="em-ds-form-element-checkbox__label" htmlFor="checkTransUnion">
                                                                <input type="checkbox"
                                                                       id="checkTransUnion"
                                                                       checked={transUnionChecked}
                                                                       onChange={handleTransUnionChange}

                                                                /><span className="em-ds-form-element-checkbox__label-text">TransUnion</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <div className="sc-qbn2kv-0 bQYKjs em-ds-form-item__feedback"
                                                               direction="row" wrap="wrap" span="2" width="" height="">
                                                            <label className="sc-1xl8m6s-1 hSxjYL" style={warningCreditBureau()}> Select at least one Credit Bureaus </label></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="sc-qbn2kv-0 jycPYI" direction="row" wrap="wrap" span="12"></div>
                                            <div className="sc-qbn2kv-0 ctPyOM" direction="row" wrap="wrap" span="12">
                                              <div className="sc-qbn2kv-0 borrower_coborrower_row" cols="2" direction="row" wrap="wrap" id="PairBioDataContainer" style={{paddingBottom: "10px", marginBottom: "8px", paddingRight: "15px"}}>
                                                <div className="sc-qbn2kv-0 borrowerRow row1 col1" direction="row" wrap="wrap" span="1" id="borrowerBioData" style={{gridTemplateColumns: "repeat(4, 1fr)", gap: "5px"}}>
                                                  <div className="sc-qbn2kv-0 borrowerRow row1 col1" direction="row" wrap="wrap" span="20" width="" height="" style={{fontWeight: "600"}}>Borrower</div>
                                                  <div className="sc-qbn2kv-0 borrowerRow row2 col1" direction="row" wrap="wrap" span="6" width="" height="">
                                                    <div className="em-ds-form-item em-ds-form-item--disabled em-ds-form-item--default em-ds-form-item--variant-default ">
                                                      <div className="em-ds-form-item-content  "><label className="em-ds-label">Last Name</label>
                                                        <div className="em-ds-input__tooltip-ref  " aria-haspopup="true"
                                                             aria-expanded="false" style={{width: "100%"}}>
                                                          <div className="em-ds-input__tooltip-ref  " style={{width: "100%"}}>
                                                            <input
                                                                className="em-ds-input em-ds-input--clearable em-ds-input--value  input-disabled"
                                                                aria-label="Text Box" aria-required="false"
                                                                aria-multiline="false" aria-autocomplete="none"
                                                                data-testid="borrower_Lastname" role="textbox" disabled
                                                                maxLength="255" minLength="0" name="" type="text"
                                                                autoComplete="off" clearable="false" value={borrowerLastName} /></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="sc-qbn2kv-0 borrowerRow row2 col2" direction="row" wrap="wrap" span="6" width="" height="">
                                                    <div
                                                        className="em-ds-form-item em-ds-form-item--disabled em-ds-form-item--default em-ds-form-item--variant-default ">
                                                      <div className="em-ds-form-item-content  "><label
                                                          className="em-ds-label">First Name</label>
                                                        <div className="em-ds-input__tooltip-ref  " aria-haspopup="true"
                                                             aria-expanded="false" style={{width: "100%"}}>
                                                          <div className="em-ds-input__tooltip-ref  " style={{width: "100%"}}>
                                                            <input
                                                                className="em-ds-input em-ds-input--clearable em-ds-input--value  input-disabled"
                                                                aria-label="Text Box" aria-required="false"
                                                                aria-multiline="false" aria-autocomplete="none"
                                                                data-testid="borrower_Firstname" role="textbox" disabled
                                                                maxLength="255" minLength="0" name="" type="text"
                                                                autoComplete="off" clearable="false" value={borrowerFirstName} /></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="sc-qbn2kv-0 borrowerRow row2 col3" direction="row" wrap="wrap" span="4" width="" height="">
                                                    <div
                                                        className="em-ds-form-item em-ds-form-item--disabled em-ds-form-item--default em-ds-form-item--variant-default ">
                                                      <div className="em-ds-form-item-content  "><label
                                                          className="em-ds-label">SS#</label>
                                                        <div className="em-ds-input__tooltip-ref  " aria-haspopup="true"
                                                             aria-expanded="false" style={{width: "100%"}}>
                                                          <div className="em-ds-input__tooltip-ref  " style={{width: "100%"}}>
                                                            <input
                                                                className="em-ds-input em-ds-input--clearable em-ds-input--value  input-disabled"
                                                                aria-label="Text Box" aria-required="false"
                                                                aria-multiline="false" aria-autocomplete="none"
                                                                data-testid="borrower_SSN" role="textbox" disabled
                                                                maxLength="255" minLength="0" name="" type="text"
                                                                autoComplete="off" clearable="false" value={borrowerSSN} />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="sc-qbn2kv-0 borrowerRow row2 col4" direction="row" wrap="wrap" span="4" width="" height="">
                                                    <div
                                                        className="em-ds-form-item em-ds-form-item--disabled em-ds-form-item--default em-ds-form-item--variant-default ">
                                                      <div className="em-ds-form-item-content  "><label
                                                          className="em-ds-label">DOB</label>
                                                        <div className="em-ds-input__tooltip-ref  " aria-haspopup="true"
                                                             aria-expanded="false" style={{width: "100%"}}>
                                                          <div className="em-ds-input__tooltip-ref  " style={{width: "100%"}}>
                                                            <input
                                                                className="em-ds-input em-ds-input--clearable em-ds-input--value  input-disabled"
                                                                aria-label="Text Box" aria-required="false"
                                                                aria-multiline="false" aria-autocomplete="none"
                                                                data-testid="borrower_Birthdate" role="textbox" disabled
                                                                maxLength="255" minLength="0" name="" type="text"
                                                                autoComplete="off" clearable="false" value={borrowerDOB} />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="sc-1440d7c-0 borrowerRow row1 col2">
                                                  {coborrowerInfo()}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="sc-qbn2kv-0 gqlSIE" direction="row" wrap="wrap" span="12">
                                              <div
                                                  className="em-ds-separator-wrapper em-ds-separator-wrapper--default em-ds-separator-wrapper--s em-ds-separator-wrapper--horizontal em-ds-separator-wrapper--initial ">
                                                <div
                                                    className="em-ds-separator em-ds-separator--default em-ds-separator--s em-ds-separator--horizontal em-ds-separator--category-level "></div>
                                              </div>
                                            </div>
                                            <div className="sc-qbn2kv-0 ctPyOM" direction="row" wrap="wrap" span="12">
                                              <div className="sc-qbn2kv-0 borrowerPairAddressContainer" cols="12" direction="row" wrap="wrap" id="borrowerPairAddressContainer" style={{gridTemplateColumns: "repeat(22, 1fr)", rowGap: "2px"}}>
                                                <div className="sc-qbn2kv-0 borrowerPairAddressContainerWrapper" direction="row" wrap="wrap" span="20" width="" height="" style={{gridTemplateColumns: "repeat(22, 1fr)", rowGap: "2px"}}>
                                                  <div className="sc-qbn2kv-0 borrowerRow row1 col1_span5" direction="row" wrap="wrap" span="5"
                                                       width="" height=""></div>
                                                  <div className="sc-qbn2kv-0 borrowerRow row1 col2_span4" direction="row" wrap="wrap" span="4"
                                                       width="" height=""><span className="sc-1yo0mbj-0 ftZzVN">Street Address</span>
                                                  </div>
                                                  <div className="sc-qbn2kv-0 borrowerRow row1 col3_span3" direction="row" wrap="wrap" span="3"
                                                       width="" height=""><span className="sc-1yo0mbj-0 ftZzVN">City</span>
                                                  </div>
                                                  <div className="sc-qbn2kv-0 borrowerRow row1 col4_span3" direction="row" wrap="wrap" span="3"
                                                       width="" height=""><span className="sc-1yo0mbj-0 ftZzVN">State</span>
                                                  </div>
                                                  <div className="sc-qbn2kv-0 borrowerRow row1 col5_span2" direction="row" wrap="wrap" span="2"
                                                       width="" height=""><span className="sc-1yo0mbj-0 ftZzVN">Zip</span>
                                                  </div>
                                                  <div className="sc-qbn2kv-0 borrowerRow row2 col1_span5" direction="row" wrap="wrap" span="5"
                                                       width="" height="" style={{alignContent: "center"}}>Borrower Current
                                                    Address
                                                  </div>
                                                  <div className="sc-qbn2kv-0 borrowerRow row2 col2_span4" direction="row" wrap="wrap" span="4"
                                                       width="" height="">
                                                    <div
                                                        className="em-ds-form-item em-ds-form-item--disabled em-ds-form-item--default em-ds-form-item--variant-default "
                                                        id="borrower_current_address_street">
                                                      <div className="em-ds-form-item-content  "><label
                                                          className="em-ds-label"></label>
                                                        <div className="em-ds-input__tooltip-ref  " aria-haspopup="true"
                                                             aria-expanded="false" style={{width: "100%"}}>
                                                          <div className="em-ds-input__tooltip-ref  " style={{width: "100%"}}>
                                                            <input
                                                                className="em-ds-input em-ds-input--clearable em-ds-input--value  input-disabled"
                                                                aria-label="Text Box" aria-required="false"
                                                                aria-multiline="false" aria-autocomplete="none"
                                                                data-testid="borrower_current_address_street" role="textbox"
                                                                disabled maxLength="255" minLength="0" name=""
                                                                type="text" autoComplete="off" clearable="false"
                                                                value={borrowerStreetAddress} /></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="sc-qbn2kv-0 borrowerRow row2 col3_span3" direction="row" wrap="wrap" span="3"
                                                       width="" height="">
                                                    <div
                                                        className="em-ds-form-item em-ds-form-item--disabled em-ds-form-item--default em-ds-form-item--variant-default "
                                                        id="borrower_current_address_city">
                                                      <div className="em-ds-form-item-content  ">
                                                        <label className="em-ds-label"></label>
                                                        <div className="em-ds-input__tooltip-ref  " aria-haspopup="true"
                                                             aria-expanded="false" style={{width: "100%"}}>
                                                          <div className="em-ds-input__tooltip-ref  " style={{width: "100%"}}>
                                                            <input
                                                                className="em-ds-input em-ds-input--clearable em-ds-input--value  input-disabled"
                                                                aria-label="Text Box" aria-required="false"
                                                                aria-multiline="false" aria-autocomplete="none"
                                                                data-testid="borrower_current_address_city" role="textbox"
                                                                disabled maxLength="255" minLength="0" name=""
                                                                type="text" autoComplete="off" clearable="false"
                                                                value={borrowerCity} /></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="sc-qbn2kv-0 borrowerRow row2 col4_span3" direction="row" wrap="wrap" span="3"
                                                       width="" height="">
                                                    <div
                                                        className="em-ds-form-item em-ds-form-item--disabled em-ds-form-item--default em-ds-form-item--variant-default "
                                                        id="borrower_current_address_state">
                                                      <div className="em-ds-form-item-content  "><label
                                                          className="em-ds-label"></label>
                                                        <div className="em-ds-input__tooltip-ref  " aria-haspopup="true"
                                                             aria-expanded="false" style={{width: "100%"}}>
                                                          <div className="em-ds-input__tooltip-ref  " style={{width: "100%"}}>
                                                            <input
                                                                className="em-ds-input em-ds-input--clearable em-ds-input--value  input-disabled"
                                                                aria-label="Text Box" aria-required="false"
                                                                aria-multiline="false" aria-autocomplete="none"
                                                                data-testid="borrower_current_address_state" role="textbox"
                                                                disabled maxLength="255" minLength="0" name=""
                                                                type="text" autoComplete="off" clearable="false" value={borrowerState} />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="sc-qbn2kv-0 borrowerRow row2 col5_span2" direction="row" wrap="wrap" span="2"
                                                       width="" height="">
                                                    <div
                                                        className="em-ds-form-item em-ds-form-item--disabled em-ds-form-item--default em-ds-form-item--variant-default "
                                                        id="borrower_current_address_zip">
                                                      <div className="em-ds-form-item-content  "><label
                                                          className="em-ds-label"></label>
                                                        <div className="em-ds-input__tooltip-ref  " aria-haspopup="true"
                                                             aria-expanded="false" style={{width: "100%"}}>
                                                          <div className="em-ds-input__tooltip-ref  " style={{width: "100%"}}>
                                                            <input
                                                                className="em-ds-input em-ds-input--clearable em-ds-input--value  input-disabled"
                                                                aria-label="Text Box" aria-required="false"
                                                                aria-multiline="false" aria-autocomplete="none"
                                                                data-testid="borrower_current_address_zip" role="textbox"
                                                                disabled maxLength="255" minLength="0" name=""
                                                                type="text" autoComplete="off" clearable="false"
                                                                value={borrowerZip} /></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {coborrowerAddres()}
                                                  {coborrowerAddres1()}
                                                  {coborrowerAddres2()}
                                                  {coborrowerAddres3()}
                                                  {coborrowerAddres4()}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="sc-qbn2kv-0 ctPyOM" direction="row" wrap="wrap" span="12" width=""
                                             height="">
                                          <div>
                                            <div
                                                className="em-ds-separator-wrapper em-ds-separator-wrapper--default em-ds-separator-wrapper--s em-ds-separator-wrapper--horizontal em-ds-separator-wrapper--initial ">
                                              <div
                                                  className="em-ds-separator em-ds-separator--default em-ds-separator--s em-ds-separator--horizontal em-ds-separator--category-level "></div>
                                            </div>
                                            <div className="indexstyled__FooterWrapper-sc-1url32y-0 cWOmil">
                                              <button
                                                  className="em-ds-button em-ds-button--secondary em-ds-button--m em-ds-button--variant-default  em-ds-button "
                                                  data-testid="button-wrapper" aria-disabled="false" aria-label="Close"
                                                  aria-pressed="false" tabIndex="0" type="button" onClick={() => closeInteraction(host)}><span
                                                  className="label-wrapper no-icon" data-testid="button-label"
                                                  role="presentation"><span>Close</span></span></button>
                                              <button
                                                  className="em-ds-button em-ds-button--primary em-ds-button--m em-ds-button--variant-default"
                                                  data-testid="button-wrapper" aria-disabled="false" aria-label="Submit"
                                                  aria-pressed="false" tabIndex="0" type="button"  onClick={verificationButtonClicked}
                                                  id="submit-button">
                                                <span className="label-wrapper no-icon" data-testid="button-label" role="presentation"><span>Submit</span></span>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                                  </TabPanel>
                                  <TabPanel value={tabValue} index={1}>
                                    <Table className={"dataTable"} columns={columns} data={rows} />
                                    <div className="indexstyled__FooterWrapper-sc-1url32y-0 cWOmil" style={{marginTop: "10px"}}>
                                      {softRefresh === true ? (
                                          <div className="refreshing-button">

                                            <TailSpin color="#777" height={25} width={25} id="refreshing" style={{marginTop: "10px"}} />
                                            <span className="refreshing-label">Refreshing...</span>
                                          </div>
                                      ) : (<div> </div>)}

                                      <button
                                          className="em-ds-button em-ds-button--secondary em-ds-button--m em-ds-button--variant-default  em-ds-button "
                                          data-testid="button-wrapper" aria-disabled="false" aria-label="Close"
                                          aria-pressed="false" tabIndex="1" type="button" onClick={() => closeInteraction(host)} style={{marginTop: "10px"}}>
                                        <span
                                          className="label-wrapper no-icon" data-testid="button-label"
                                          role="presentation"><span>Close</span></span></button>

                                       <button  className="em-ds-button em-ds-button--primary em-ds-button--m em-ds-button--variant-default"
                                                  data-testid="button-wrapper" aria-disabled="false" aria-label="Refresh"
                                                  aria-pressed="false" tabIndex="0" type="button"  onClick={() => openNewPanel({"name": "refresh"})}>
                                          <span className="label-wrapper no-icon" data-testid="button-label" role="presentation"><span>Refresh</span></span>
                                        </button>

                                    </div>
                                  </TabPanel>
                                </Box>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            </LoadingOverlay>
          </div>
      )}
    </>
  );
}

export default VerificationStartPage;
