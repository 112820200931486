import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import VerificationStarted from "./pages/VerificationStartedPage";
import CreditStartPage from "./pages/CreditStartPage";
import ExceptionCatchedPage from "./pages/ExceptionCatchedPage";
import host from "@elliemae/em-ssf-guest";

function App() {
  host.connect();
  return (
    <Router>
      <div className="App">
        {/*<Header data={{ host: host }} />*/}
        <Routes>
          <Route exact path="/" element={<CreditStartPage data={{ host: host }} />}></Route>
          <Route exact path="/result" element={<VerificationStarted data={{ host: host }} />}></Route>
          <Route exact path="/error" element={<ExceptionCatchedPage data={{ host: host }} />}></Route>
        </Routes>
      </div>
    </Router>
  );
}
export default App;
