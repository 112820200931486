const product_name = "credit";
let service_endpoint = "/encompass-credit-application-ui"
let transaction_id = "encompass-ui-credit-application-transaction"
let failed_transaction_id = "encompass-ui-credit-application-transaction-failed"

async function create_health_metric(host_environment, api_key,
                             operation_status, health_data, error_log, metric_type, logging_message,
                             _transaction_id, _service_endpoint) {

  try {
    if(_transaction_id)
      transaction_id = _transaction_id
    if(_service_endpoint)
      service_endpoint = _service_endpoint

    let health_record_payload = {
      host_environment: host_environment,
      product_name: product_name,
      service_endpoint: service_endpoint
    }
    if(operation_status)
      health_record_payload['operation_status'] = operation_status
    if(health_data)
      health_record_payload['data'] = health_data
    if(error_log)
      health_record_payload['error'] = error_log
    if(metric_type)
      health_record_payload['metric_type'] = metric_type
    else
      health_record_payload['metric_type'] = "transaction"
    if(logging_message)
      health_record_payload['logging_message'] = logging_message

    let url = 'https://' + host_environment + '/code-health-checker/metric/' + transaction_id
    let  headers = {'x-api-key': api_key, 'content-type': 'application/json'}

    const res = await fetch(
      url,
      {
        method: "POST",
        mode: "cors",
        headers: headers,
        body: JSON.stringify(health_record_payload),
      }
    );
    if (res.ok) {
      const data = await res.json();
      // console.log("HEALTH stored")
    }
    else {
      console.log(res);
    }
  } catch (e) {
    console.error("Error while downloading report", e);
  }
}

async function create_health_metric_with_job(operation_status, logging_message, domain) {
  try {
    let health_record_payload = {
        "domain": domain,
        "status": operation_status,
        "message": logging_message,
        "transaction_id": transaction_id
    }
    if(operation_status === "FAILED"){
      health_record_payload["transaction_id"] = failed_transaction_id
    }

    let url = 'https://bushnell-production.staircaseapi.com/job/webhooks/health-log'
    let  headers = {'content-type': 'application/json'}

    const res = await fetch(
      url,
      {
        method: "POST",
        mode: "cors",
        headers: headers,
        body: JSON.stringify(health_record_payload),
      }
    );
    if (res.ok) {
      const data = await res.json();
      // console.log("HEALTH stored")
    }
    else {
      console.log(res);
    }
  } catch (e) {
    console.error("Error while creating health report", e);
  }
}




export {
  create_health_metric, create_health_metric_with_job
};
